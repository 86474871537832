import Logo from "./assets/img/logo.png";
import Banner from "./assets/img/banner.webp";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="max-w-[1180px] mx-auto px-2 md:px-5">
        <header className="pt-12">
          <div className="flex flex-col items-center gap-5">
            <div className="w-[300px]">
              <img className="w-full" src={Logo} alt="logo" />
            </div>
            {/* <ul className="flex gap-5">
              <li>
                <a className="pb-2 border-b-4 border-transparent transition-all hover:border-[#000]">
                  Home
                </a>
              </li>
              <li>
                <a className="pb-2 border-b-4 border-transparent transition-all hover:border-[#000]">
                  About
                </a>
              </li>
              <li>
                <a className="pb-2 border-b-4 border-transparent transition-all hover:border-[#000]">
                  Roadmap
                </a>
              </li>
            </ul> */}
          </div>
        </header>

        <main>
          <section className="pt-10 text-center">
            <h4 className="text-3xl mt-5">We are $COIN</h4>
            <span className="block mt-5 text-lg">
              0x3e544EEC1fDe17Fa17dA148Bbcfad9be2003E838
            </span>
            <span className="block mt-5 text-lg">
              Most expensive coin in the world
            </span>
            <div className="flex flex-col gap-5 justify-between items-center mt-12 md:flex-row">
              <a
                className="flex items-center justify-center gap-2 bg-white w-[150px] h-[50px] rounded-lg font-bold transition-all hover:opacity-80"
                href="https://medium.com/@Coin_Erc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 640 512"
                >
                  <path d="M180.5,74.262C80.813,74.262,0,155.633,0,256S80.819,437.738,180.5,437.738,361,356.373,361,256,280.191,74.262,180.5,74.262Zm288.25,10.646c-49.845,0-90.245,76.619-90.245,171.095s40.406,171.1,90.251,171.1,90.251-76.619,90.251-171.1H559C559,161.5,518.6,84.908,468.752,84.908Zm139.506,17.821c-17.526,0-31.735,68.628-31.735,153.274s14.2,153.274,31.735,153.274S640,340.631,640,256C640,171.351,625.785,102.729,608.258,102.729Z" />
                </svg>
                <span>Medium</span>
              </a>
              <a
                className="flex items-center justify-center gap-2 bg-white w-[150px] h-[50px] rounded-lg font-bold transition-all hover:opacity-80"
                href="https://twitter.com/COIN_1794"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  style={{ width: "1.2em" }}
                >
                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                </svg>
                <span>Twitter</span>
              </a>
              <a
                className="flex items-center justify-center gap-2 bg-white w-[150px] h-[50px] rounded-lg font-bold transition-all hover:opacity-80"
                href="https://t.me/COIN_1794"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 496 512"
                  style={{ width: "1.2em" }}
                >
                  <path d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"></path>
                </svg>
                <span>Telegram</span>
              </a>
            </div>
          </section>

          <section className="pt-[80px] flex flex-col items-center justify-between gap-5 md:flex-row">
            <p className="w-[100%] md:w-[60%]">
              OThe Flowing Hair dollar was the first dollar coin issued by the
              United States federal government. The coin was minted in 1794 and
              1795; its size and weight were based on the Spanish dollar, which
              was popular in trade throughout the Americas.
              <br />
              <br />
              In 1791, following a study by Alexander Hamilton, Congress passed
              a joint resolution calling for the establishment of a national
              mint. Later that year, in his third State of the Union address,
              President George Washington urged Congress to provide for a mint,
              which was officially authorized by the Coinage Act of 1792.
              Despite the authorization, silver and gold coins were not struck
              until 1794. The Flowing Hair dollar, designed by Robert Scot, was
              initially produced in 1794, and again in 1795. In October 1795 the
              design was replaced by the Draped Bust dollar.
              <br />
              <br />
              Flowing Hair dollar was sold at auction for $10,016,875, the
              highest selling price of any coin in history.
            </p>
            <div className="max-w-[320px]">
              <img className="w-full mx-auto" src={Logo} alt="logo" />
            </div>
          </section>

          <section className="py-[80px] flex flex-col items-center gap-5 md:flex-row">
            <div className="w-[100%] md:w-[40%]">
              <img
                className="w-[60%] mx-auto md:w-full rounded-full"
                src={Banner}
                alt="Banner"
              />
            </div>
            <div className="flex items-start justify-center gap-5 w-full md:w-[60%] md:gap-12">
              <ul>
                <li>Total Supply</li>
                <li className="mt-2">Tax</li>
                <li className="mt-2">LP</li>
                <li className="mt-2">Renounced</li>
              </ul>
              <ul>
                <li>1,794,000,000,000</li>
                <li className="mt-2">0/0</li>
                <li className="mt-2">Burned</li>
                <li className="mt-2">Ownership</li>
              </ul>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}

export default App;
